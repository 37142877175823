import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaCircle } from "react-icons/fa";
import * as APIController from "../../API/APIController";
import { Button, Card, StripPayment, SuperPayment, Text } from '../../components';
import { useTheme } from '../../config';
import { PAYMENTMETHOD, UUID } from '../../config/appConstant';
import './Payment.css';
import { useDispatch, useSelector } from 'react-redux';
import { setBundle } from '../../redux/store';

const Payment = (props) => {
  const { backButttonPress, selectBundle } = props
  const { theme } = useTheme()
  const dispatch = useDispatch();
  const BaseColor = useTheme().theme.colors;
  const S_T = useSelector((state) => state.S_T);
  const [GUID, setGUID] = useState('')
  const { t } = useTranslation();
  const [Amount, setAmount] = useState(selectBundle.PriceTotal)
  const [ChoosePaymentMethod, setChoosePaymentMethod] = useState(-1)



  const ChoosePayment = (val, Amount) => {
    setChoosePaymentMethod(val)
    wsPaymentInitiate(Amount, UUID(), val == 0 ? 'SP' : 'ST')
  }
  const wsPaymentInitiate = (FundAmount, RequestGuid, PaymentType) => {
    setGUID(RequestGuid)
    let object = {
      ...S_T,
      RequestGuid: RequestGuid
    }
    console.log('object papappap:', object)

    dispatch(setBundle(object));
    APIController.PaymentInitiate(FundAmount, RequestGuid, PaymentType)
      .then((response) => {
      }).catch((err) => {
        console.log('err:', err);
      });
  };

  return (
    <div className='payment-method-container'>
      <div className='Payment-Support_msg'>
        <Text accentColor>{t("Support_Msg")}</Text>
      </div>
      {
        ChoosePaymentMethod == -1 && <>
          {PAYMENTMETHOD?.map((item, index) => {
            return (
              <Card onClick={() => ChoosePayment(index, selectBundle.PriceTotal)} className='payment-type-button' CardColor={index == ChoosePaymentMethod ? theme.colors.darkGray : theme.colors.lightAccent}>
                <FaCircle color={index == ChoosePaymentMethod ? theme.colors.card : theme.colors.text} />
                <img className='payment-type-icon' fetchpriority="high" as="image" src={item.Image} />
                <div className='payment-texts'>
                  <Text title2>{t(item.Name)}</Text>
                  <Text DarkGrayTextColor >{t(item.subdescription)}</Text>
                </div>
              </Card>
            )
          }
          )}
        </>
      }
      {
        ChoosePaymentMethod == 0 ? <SuperPayment Amount={Amount} GUID={GUID} /> :
          ChoosePaymentMethod == 1 && <StripPayment Amount={Amount} GUID={GUID} />
      }

    </div >
  );
}

export default Payment;
