import { IconButton } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IoIosArrowRoundBack } from "react-icons/io";
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, Nodata, PaymentView, Text } from '../../components';
import { Images, useTheme } from '../../config';
import { bytesToSize, formatCurrency, IsEmpty } from '../../config/appConstant';
import { setBundle } from '../../redux/store';
import './SelectedBundle.css';

const SelectedBundle = (props) => {
    const { selectBundle } = props;
    const S_T = useSelector((state) => state.S_T);
    const dispatch = useDispatch();
    const BaseColor = useTheme().theme.colors;
    const { t } = useTranslation();
    const [showPaymentView, setshowPaymentView] = useState(false);

    useEffect(() => {
        if (!IsEmpty(selectBundle)) {
            setshowPaymentView(true);
        }
    }, [selectBundle]);

    const handleContinue = () => {
        let Object = {
            ...S_T,
        };
        dispatch(setBundle(Object));
        setshowPaymentView(true);
    };

    if (IsEmpty(selectBundle)) {
        return (
            <div style={{ marginTop: '28px' }}>
                <Nodata Message={t('NotSelectedBundle')} />
            </div>
        );
    }

    return (

        <div className="align-success-text">
            <Text heading2>{t("Choose_Payment_Method")}</Text>
            <PaymentView selectBundle={selectBundle} backButttonPress={() => setshowPaymentView(false)} />
        </div>
    );
};

export default SelectedBundle;
