import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Text } from "../../components";
import { capitalizeFirstLetter } from '../../config/appConstant';
import './Breadcrumbs.css';
import { useTranslation } from 'react-i18next';

const Breadcrumbs = ({ items }) => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  let ScreenName = capitalizeFirstLetter(window.location.pathname.split('/')[1])
  let itemsDynamic = items || ['Home', `${ScreenName}`]
  const onnavigate = (item) => {
    if (item != ScreenName)
      navigate(`/${(item).toLowerCase()}`)
  }

  return (

    <div className="breadcrumbs">
      {itemsDynamic?.map((item, index) => (
        <span onClick={() => onnavigate(item)} key={index} className="breadcrumb-item">
          <Text DarkGrayTextColor>
            {t(item)}
          </Text>
          {index < itemsDynamic.length - 1 && <span className="breadcrumb-separator"> &gt; </span>}
        </span>
      ))}
    </div>
  );
};

export default Breadcrumbs;
