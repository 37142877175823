import React, { useEffect, useState } from 'react';
import './BundleList.css';
import { Accordion, Button, Card, CommonLoader, CommonModal, Nodata, Text } from '../../components';
import { Images, useTheme } from '../../config';
import * as APIController from "../../API/APIController";
import { bytesToSize, formatCurrency, IsEmpty } from '../../config/appConstant';
import { useNavigate } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import DetailsModal from '../../screens/DetailsModal/DetailsModal';


const BundleList = (props) => {
    const { handeBundleSelect, searchValue, selectCountryRegion } = props
    const { t } = useTranslation();
    const BaseColor = useTheme().theme.colors
    const navigate = useNavigate()
    const [BundleList, setBundleList] = useState([]);
    const [BundleListHolder, setBundleListHolder] = useState([]);
    const [Loading, setLoading] = useState(true);
    const [isRoamingModalOpen, setRoamingModalOpen] = useState(false);
    const [roamingData, setRoamingData] = useState([]);
    const [isDetailsModalOpen, setDetailsModalOpen] = useState(false); // State for DetailsModal
    const [selectedBundleDetails, setSelectedBundleDetails] = useState(null); // State for selected bundle
    console.log('selectedBundleDetails:', selectedBundleDetails)

    useEffect(() => {
        if (!IsEmpty(selectCountryRegion)) {
            if (selectCountryRegion.selectedTab == 1) {
                wsGetESIMBundles('', selectCountryRegion.CountryName)
            } else {
                wsGetESIMBundles(selectCountryRegion.CountryName, '')
            }
        }
    }, []);


    useEffect(() => {
        if (!IsEmpty(searchValue)) {
            setBundleList(BundleListHolder.filter(destination => destination.BundleDescription.toLowerCase().includes(searchValue)));
        } else {
            setBundleList(BundleListHolder)
        }
    }, [searchValue]);

    const wsGetESIMBundles = (CountryName, Region) => {
        APIController.GetESIMBundles(CountryName, Region)
            .then((response) => {
                setLoading(false)
                if (IsEmpty(response.error)) {
                    if (response.object.Status == 1) {
                        setBundleList(response.object.BundleList);
                        setBundleListHolder(response.object.BundleList);
                    }
                }
            }).catch((err) => {
                console.log('err:', err);
            });
    };


    const handleRoamingClick = (roamingEnabled) => {
        setRoamingData(roamingEnabled);
        setRoamingModalOpen(true);
    };

    const handleDetailsClick = (bundle) => {
        setDetailsModalOpen(true); // Open the DetailsModal
        setSelectedBundleDetails(bundle); // Set the selected bundle data

    };
    return (
        <div>
            <div className='align-success-text'>
                <Text DarkGrayTextColor>{t('Please_Select_Bundle')}</Text>
            </div>
            <div className='bundle-flex-main'>
                {Loading && <CircularProgress color={'primary'} />}
                {(IsEmpty(BundleList) && !Loading) ?
                    <Nodata /> :
                    <>
                        {BundleList.map((item, index) => {
                            return (
                                <Card elevation={5} style={{ borderRadius: '15px', margin: 5, }} CardColor={BaseColor.card} >
                                    <div className="bundle-card">
                                        <div className='icon-plan-flex'>
                                            <div> <img src={item.CountryImage} width={50} /></div>
                                            {/* <div> <Text heading>{item.CountryEmoji} </Text></div> */}
                                            <div>
                                                <div className='data-plan'><Text whiteColor>{(item.DataAmount == -1 ? 'Unlimited' : bytesToSize(item.DataAmount * 1000000)) + ' / ' + (item.Duration > 1 ? (item.Duration + ' Days') : (item.Duration + ' Day'))}</Text></div>
                                            </div>
                                        </div>
                                        <div className='align-bundle-text'>
                                            <Text DarkGrayTextColor>{item.CountryName}</Text>
                                        </div>
                                        <div className='price-bundle-flex'>
                                            <Text bold mainColor>{item.BundleDescription}</Text>
                                            <Text DarkGrayTextColor>{formatCurrency(item.PriceTotal)}</Text>
                                        </div>
                                        <div className='align-bundle-text'>
                                            <Text subhead PurpleColor>{(item.BundleType == 'UL' ? 'Unlimited Lite: ': item.BundleType == 'UP' ? 'Unlimited Plus: ' : '' )}</Text>
                                            <Text subhead DarkGrayTextColor>{item.BundleTypeDescription}</Text>
                                        </div>
                                        {!IsEmpty(item.roamingEnabled) && (
                                            <div className='align-bundle-text roaming-text' onClick={() => handleRoamingClick(item.roamingEnabled)}>
                                                <Text subhead mainColor>{t('ViewRoamingCountry')}</Text>
                                            </div>
                                        )}
                                      <div className='bundle-select'>
                                        <Button round type={2} onClick={() => { handeBundleSelect(item) }} title={t('Select')} />
                                        <img src={Images.ArrowIcon} />
                                        <div className="bundle-text" onClick={() => handleDetailsClick(item)}> <Text subhead mainColor >Details</Text></div> 
                                     </div>

                                    </div>
                                </Card>
                            )
                        })}
                    </>}

            </div>
            <div>

            </div>

            <CommonModal IsScroll={false} maxWidth={'sm'} onClose={() => setRoamingModalOpen(false)} visible={isRoamingModalOpen} >
                <div>
                    <div className='Roaming-main'>
                        <div className='roaming-heading'>
                            <Text title1 PurpleColor bold >Roaming Countries</Text>
                        </div>
                        <div className='roaming-flex-wrap' >
                            {roamingData.map((country, idx) => (
                                <div key={idx} className='roaming-country'>
                                    <Text title1>{country.CountryEmoji}</Text>
                                    <Text whiteColor>{country.CountryName} </Text>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </CommonModal>
            <CommonModal IsScroll={false} maxWidth={'lg'}  onClose={() => setDetailsModalOpen(false)} visible={isDetailsModalOpen}>
                <DetailsModal bundle={selectedBundleDetails} handeBundleSelect={handeBundleSelect} setDetailsModalOpen={setDetailsModalOpen}/>
            </CommonModal>

        </div>

    );
}
export default BundleList;
