import React, { useState, useEffect } from 'react';
import './navbar.css';
import { CommonLoader, CommonModal, SingaleCheckBox, Text } from '../../components';
import { useTranslation } from 'react-i18next';
import { languages, ThemeSupport } from '../../config/appConstant';
import { useDispatch, useSelector } from 'react-redux';
import { setDark, setLanguage, setTheme } from '../../redux/store';
import { FaLanguage } from "react-icons/fa6";
import { BaseColor } from '../../config';
import CommonSwitch from '../Switch/CommonSwitch';
import { MdOutlineDownloadDone } from "react-icons/md";

function LanguageDropdown() {
  const { t, i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [isSidebar, setIsSidebar] = useState(false);
  const [Loader, setLoader] = useState(false);
  const force_dark = useSelector((state) => state.force_dark);
  const theme = useSelector((state) => state.theme);

  const dispatch = useDispatch();

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1040) {
        setIsSidebar(true);
      } else {
        setIsSidebar(false);
      }
    };

    window.addEventListener('resize', handleResize);

    // Set initial state on load
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    dispatch(setLanguage(lng));
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const ChangeTheme = (name) => {
    setLoader(true);
    dispatch(setTheme(name));
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  const ForceDark = (name) => {
    setLoader(true);
    dispatch(setDark(name));
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  return (
    <>
      {Loader && <CommonLoader />}

      {isSidebar ? (
        <>
          <div className={`sidebar ${isOpen ? 'open' : ''}`}>
            <div style={{ paddingLeft: 5, borderTop: '1px solid', borderTopColor: BaseColor.mainColor }}>
              <Text title3 bold >Languages</Text>
            </div>
            <div className="sidebar-menu">
              {languages.map((language) => (
                <div onClick={() => changeLanguage(language.code)} key={language.code} className="dropdown-item">
                  <Text callout>{language.name}</Text>
                </div>
              ))}
              <div style={{ paddingLeft: 5, borderTop: '1px solid', borderTopColor: BaseColor.mainColor }}>
                <Text title3 bold>Theme</Text>
              </div>
              {ThemeSupport.map((themeOption) => (
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                  onClick={() => ChangeTheme(themeOption.name)}
                  key={themeOption.code}
                  className="dropdown-item"
                >
                  <Text callout>{themeOption.name}</Text>
                  {themeOption.name === theme && <MdOutlineDownloadDone fontSize={25} />}
                </div>
              ))}
              <div
                style={{
                  paddingLeft: 5,
                  display: 'flex',
                  flexDirection: 'column',
                  borderTop: '1px solid',
                  borderTopColor: BaseColor.mainColor
                }}
              >
                <Text title3 bold>Dark</Text>
                <CommonSwitch checked={force_dark} onChange={(e) => ForceDark(e.target.checked)} />
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="dropdown">
          <button className="dropdown-toggle" onClick={toggleDropdown}>
            <FaLanguage style={{ color: '#fff', fontSize: 30 }} />
          </button>
          {isOpen && (
            <div className="dropdown-menu">
              {languages.map((language) => (
                <div onClick={() => changeLanguage(language.code)} key={language.code} className="dropdown-item">
                  <Text>{language.name}</Text>
                </div>
              ))}
              <div style={{ paddingLeft: 5, borderTop: '1px solid', borderTopColor: BaseColor.mainColor }}>
                <Text bold primaryColor>Theme</Text>
              </div>
              {ThemeSupport.map((themeOption) => (
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                  onClick={() => ChangeTheme(themeOption.name)}
                  key={themeOption.code}
                  className="dropdown-item"
                >
                  <Text capitalize>{themeOption.name}</Text>
                  {themeOption.name === theme && <MdOutlineDownloadDone fontSize={25} />}
                </div>
              ))}
              <div
                style={{
                  paddingLeft: 5,
                  display: 'flex',
                  flexDirection: 'column',
                  borderTop: '1px solid',
                  borderTopColor: BaseColor.mainColor
                }}
              >
                <Text bold primaryColor>Dark</Text>
                <CommonSwitch checked={force_dark} onChange={(e) => ForceDark(e.target.checked)} />
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default LanguageDropdown;
