import React, { useState } from 'react';
import './ForgetPassword.css';
import { Button, Text, TextField } from '../../components';
import { useTranslation } from 'react-i18next';
import * as APIController from "../../API/APIController";
import { toast } from 'react-toastify';
import { AppConstant, IsEmpty } from '../../config/appConstant';
import MobileInput from '../../components/MobileInput/MobileInput';

function ForgetPassword({ onSuccess }) {
    const { t } = useTranslation();
    const [email, setEmail] = useState('');
    const [customerCode, setCustomerCode] = useState('');
    const [mobileno, setMobileno] = useState('');
    const [loading, setLoading] = useState(false);
    const [Sucessresponse, setSucessresponse] = useState('');
    const [CountryCode, setCountryCode] = useState('');

    const validate = () => {
        if (!email && !customerCode && !mobileno) {
            toast.error('Please fill in at least one field: Email, Customer Code, or Mobile No');
            return false;
        }

        if (email && !validateEmail(email)) {
            toast.error('Please enter a valid email address.');
            return false;
        }

        if (mobileno && mobileno.length < 10) {
            toast.error('Mobile number must be at least 10 digits.');
            return false;
        }

        return true;
    };

    const validateEmail = (email) => {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@(([^<>()[\]\\.,;:\s@"]+\.)+[^<>()[\]\\.,;:\s@"]{2,})$/i;
        return re.test(String(email).toLowerCase());
    };

    const handleResetPassword = () => {
        if (validate()) {
            wsForgetPassword(email, mobileno, customerCode, CountryCode);
        }
    };

    const wsForgetPassword = (EmailAddress, MobileNumber, CustomerCode, CountryCode) => {
        setLoading(true)
        APIController.ForgotPassword(EmailAddress, MobileNumber, CustomerCode, CountryCode)
            .then((response) => {
                if (IsEmpty(response.error)) {
                    setLoading(false)
                    if (response.object.Status == 1) {
                        setSucessresponse(response.object.Message);
                        toast.success(response.object.Message);
                        if (onSuccess) {
                            onSuccess();
                        }

                    } else {
                        toast.error(response.object.Message);
                    }
                } else {
                    toast.error('An error occurred. Please try again.');
                }
            }).catch((err) => {
                console.log('err:', err);
                toast.error('Something went wrong.');
            });
    };

    return (
        <div className="forgot-password-container">
            <div className='Forget_heading'>
                <Text headline bold>Forgot Password</Text>
            </div>
            <div className="form-group">
                <TextField
                    value={email}
                    name={'EmailAddress'}
                    onChange={(e) => { setEmail(e.target.value); }}
                    placeholder={t('Email_Address')}
                    required
                    error={email && !validateEmail(email)} // Error only if email is entered and invalid
                />
            </div>

            <div className="separator">
                <div className="line"></div>
                <span>OR</span>
                <div className="line"></div>
            </div>

            <div className="form-group">
                <TextField
                    value={customerCode}
                    name={'CustomerCode'}
                    onChange={(e) => { setCustomerCode(e.target.value); }}
                    placeholder={t('Customer Code')}
                    required
                    error={customerCode && customerCode === ''} // Error only if customerCode is entered and invalid
                />
            </div>

            <div className="separator">
                <div className="line"></div>
                <span>OR</span>
                <div className="line"></div>
            </div>

            <div className="form-group">
                <MobileInput
                    placeholder={t('Mobile No')}
                    value={`${CountryCode}${mobileno}`}
                    onChange={(phone, countryCode) => {
                        setMobileno(phone);
                        setCountryCode(countryCode);
                    }}
                    error={mobileno && mobileno.length < 10}
                />
            </div>

            <div className='forgetpassword-button'>
                <Button
                    loading={loading}
                    capsule
                    onClick={handleResetPassword}
                    full
                    Large
                    round
                    type={2}
                    title={t('Get Password Reset Password')}
                />
            </div>
        </div>
    );
}

export default ForgetPassword;
