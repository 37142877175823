import React, { useEffect } from 'react';
import TagManager from 'react-gtm-module';

const GTMInitializer = ({ gtmId, events = {} }) => {
  useEffect(() => {
    if (!gtmId) {
      console.warn('GTM ID is required for initializing Google Tag Manager');
      return;
    }

    const tagManagerArgs = {
      gtmId: gtmId,
      events: events,
    };

    TagManager.initialize(tagManagerArgs);
    console.log('Google Tag Manager initialized:', tagManagerArgs);
  }, [gtmId, events]);

  return null; // This component does not render anything
};

export default GTMInitializer;
