import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { isValidToken } from '../../config/appConstant';
import { setLogin } from '../../redux/store';

const TokenExpiryComponent = ({ tokenExpireAt, minutesToExpire }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate(); // React Router hook for navigation
  const UserDetail = useSelector((state) => state.userdetail);

  // List of screen names where token expiry check should be skipped
  const excludedScreens = ['/login', '/SignUp', '/forgotpassword', '/welcome', '/otp', '/privacypolicy', '/deleteaccount', 'termsandcondition']; // Update with route paths

  // Function to handle logout and navigation to login screen
  const handleLogout = async () => {
    localStorage.removeItem('Access_Token');
    dispatch(setLogin(false));
    navigate('/');
    toast.success(t('Sesion_Expire'));
  };


  useEffect(() => {
    // Get the current route path
    const currentPath = window.location.pathname;
    // Only check token expiration if the current screen is not in the excluded list
    if (!excludedScreens.includes(currentPath)) {
      if (!isValidToken(UserDetail.TokenDateTimeValid, UserDetail.ExpiredAt)) {
        handleLogout();
      }
    }
  }, [UserDetail, tokenExpireAt, minutesToExpire]);

  return <></>;
};

export default TokenExpiryComponent;
