import React, { useEffect, useState } from 'react';
import FacebookLogin from 'react-facebook-login';
import GoogleLogin from 'react-google-login';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { API } from '../../API/API';
import { URLs } from '../../API/URLs';
import { Button, CommonModal, Footer, GTMInitializer, Text, TextField } from '../../components';
import { Images } from '../../config';
import './SignIn.css';
import { useDispatch, useSelector } from 'react-redux';
import { setloginDetail } from '../../redux/store';
import { AppConstant, GTMID } from '../../config/appConstant';
import { setLogin } from '../../redux/store';
import ForgetPassword from '../ForgetPassword/ForgetPassword';
import { jwtDecode } from "jwt-decode";
import { toast } from 'react-toastify';
import moment from 'moment';

const SignIn = (props) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [emailaddress, setEmailAdress] = useState('');
    const [password, setPassword] = useState('');
    const [touched, setTouched] = useState(false);
    const [loading, setloading] = useState(false);
    const [openForgetpassword, setopenForgetpassword] = useState(false);
    const UserDetails = useSelector((state) => state.userdetail);
    const [loginError, setLoginError] = useState('');

    const dispatch = useDispatch();
    const location = useLocation();
    const { email } = location.state || {};

    const gtmId = GTMID; // Replace with your GTM ID
    const customEvents = {
        sendUserInfo: 'Login Screen Visit', // Example custom event
    };

    useEffect(() => {
        setEmailAdress(email);
    }, [email]);

    useEffect(() => {
        let data = {
            ...UserDetails,
            IsRegisterComplete: 1,
        }
        dispatch(setloginDetail(data));
    }, [props]);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Enter') {
                validate();
            }
        };
        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [emailaddress, password]);

    const validateEmail = (email) => {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@(([^<>()[\]\\.,;:\s@"]+\.)+[^<>()[\]\\.,;:\s@"]{2,})$/i;
        return re.test(String(email).toLowerCase());
    };

    const validate = () => {
        if (!emailaddress || !password) {
            setTouched(true);
            return false;
        } else if (!validateEmail(emailaddress)) {
            return false;
        } else {
            wsLogin();
        }
    };

    const wsLogin = () => {
        setloading(true);
        var details = {
            Username: emailaddress,
            Password: password,
            Code: AppConstant.CustomerCode,
            grant_type: 'password',
        };
        var formBody = [];
        for (var property in details) {
            var encodedKey = encodeURIComponent(property);
            var encodedValue = encodeURIComponent(details[property]);
            formBody.push(encodedKey + '=' + encodedValue);
        }
        formBody = formBody.join('&');

        let url = URLs.base + '/token';
        return new Promise((resolve, reject) => {
            API.shared.request('POST', url, formBody)
                .then((response) => {
                    setloading(false);
                    if (response.error == null) {
                        const decoded = jwtDecode(response.jwt);
                        let FullDatetime = moment().format('MM/DD/YYYY HH:mm:ss');
                        let data = {
                            ...decoded,
                            EmailID: emailaddress,
                            IsRegisterComplete: 1,
                            ExpiredAt: response.SessionTimeout,
                            TokenDateTimeValid: FullDatetime
                        }
                        dispatch(setloginDetail(data));
                        props.onClose()
                        localStorage.setItem('Access_Token', response.access_token);
                        if (response.IsEmailOTPVerifyRequired === "False") {
                            if (props.onLoginSuccess) {
                                props.onLoginSuccess();
                            }
                            dispatch(setLogin(true));

                        } else {
                            navigate('/otp', { state: { email: emailaddress } });
                        }
                    } else {
                        toast.error(response.error_description)
                        setLoginError(response.error_description);  // Display error message

                    }
                })
                .catch((error) => {
                    setloading(false);
                    console.error(error);
                });
        });
    };

    const responseFacebook = (response) => {
        console.log('Facebook login response:', response);
        navigate('/signup', { state: { Email: response.email, name: response.name, userId: response.userID }, replace: true });
    };

    const responseGoogle = (response) => {
        console.log('responseGoogle:', response);
    };

    const failureGoogle = (error) => {
        console.error('Google Login Error:', error);
    };

    const failureFacebook = (error) => {
        console.error('Facebook Login Error:', error);
    };
    const handleforgetpassword = () => {
        setopenForgetpassword(true)
    }
    return (
        <>
            <div className=''>
                <GTMInitializer gtmId={gtmId} events={customEvents} />

                <div className="login-container">
                    {/* <div className="login-left">
                        <div className="image-placeholder">
                            <span className="image-icon">&#x1F5BC;</span>
                        </div>
                    </div> */}
                    <div className="login-right">
                        <div className='signup-heading'>
                            <Text bold title2>{t('Sign_In')}</Text>
                        </div>
                        <div className="input-group">
                            <TextField
                                value={emailaddress}
                                name={'EmailAddress'}
                                onChange={(e) => {
                                    setEmailAdress(e.target.value);
                                    setLoginError(''); // Reset the error message when user starts typing
                                }}
                                placeholder={t('Email_Address')}
                                required
                                error={emailaddress == '' && touched ? true : false}
                            />
                        </div>
                        <div className="input-group">
                            <TextField
                                value={password}
                                name={'Password'}
                                onChange={(e) => {
                                    setPassword(e.target.value);
                                    setLoginError(''); // Reset the error message when user starts typing
                                }}
                                placeholder={t('Password')}
                                type="password"
                                error={password == '' && touched ? true : false}
                            />
                        </div>
                        <div className="forgot-password" onClick={handleforgetpassword}>
                            <Text> {t("Forgot_password")}</Text>
                        </div>
                        {loginError &&
                            <div className="error-message">
                                {loginError}
                            </div>
                        }


                        <div style={{ display: 'flex' }}>
                            <Button disabled={loading} loading={loading} capsule full type={2} onClick={() => validate()} title={t('Sign_In')} />
                            <Button style={{ marginLeft: '10px' }} capsule full type={2} onClick={props.onSignUpClick} title={t('Sign_Up')} />
                        </div>

                    </div>
                </div>
            </div>
            <CommonModal IsScroll={false} maxWidth={'md'} onClose={() => setopenForgetpassword(false)} visible={openForgetpassword} >
                <ForgetPassword onSuccess={() => setopenForgetpassword(false)} />
            </CommonModal>
        </>
    );
}

export default SignIn;
