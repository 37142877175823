// src/Footer.js

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Text, TextField } from '../../components';
import { Images } from '../../config';
import { AppConstant, getCurrentYear } from '../../config/appConstant';
import './Footer.css';

const Footer = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [touched, setTouched] = useState(false);

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  }

  return (
    <footer className="footer-main-container">
      <div className="footer-container">
        <div className="footer-section logo">
          <img src={Images.mainlogo} alt="Logo" />
          <div className='logo-text' style={{ display: "none" }}>
            <Text lineheightsmall caption2>Lorem ipsum dolor sit amet consectetur. Sit
              malesuada maecenas lectus malesuada dui
              auctor natoque dui. Morbi congue sociis
              hac velit. Est ultricies nulla et id.</Text>
          </div>
          <div className="social-icons">
            <a href="https://x.com/e_Simlife" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-twitter"></i>
            </a>
            <a href="https://www.facebook.com/profile.php?id=61566480296287" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-facebook"></i>
            </a>
            <a href="https://www.instagram.com/esimlife/" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-instagram"></i>
            </a>
            <a href="https://www.tiktok.com/@esimlife" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-tiktok"></i>
            </a>
          </div>
        </div>
        <div className="footer-section about">
          <div className='footer-heading-text'><Text lineheighthigh title>{t('footer_heading_one')}</Text></div>
          <ul>
            {/* <li><a >{t('footer_litext_one')}</a></li>
            <li><a >{t('footer_litext_two')}</a></li>
            <li><a >{t('footer_litext_three')}</a></li> */}
            <li><a href="/aboutus">{t('About Us')}</a></li>
            <li><a href="/contactus">{t('Contact Us')}</a></li>
            <li><a href="/gethelp">{t('Get Help')}</a></li>
            <li><a href="/myeSIM" >{t('My eSIM')}</a></li>
          </ul>
        </div>
        <div className="footer-section links">
          <div className='footer-heading-text'><Text lineheighthigh title>{t('footer_heading_two')}</Text></div>
          <ul>
            <li><a href="/termsandcondition">{t('Terms and Condition')}</a></li>
            <li><a href="/privacypolicy">{t('Privacy Policy')}</a></li>
            <li><a href="/righttodelete">{t('Right to delete')}</a></li>
            {/* <li><a >{t('footer_litext_five')}</a></li>
            <li><a >{t('footer_litext_six')}</a></li>
            <li><a >{t('footer_litext_seven')}</a></li> */}
          </ul>
        </div>
        <div className="footer-section ">
          <div className='footer-heading-text'><Text lineheighthigh title>{t('footer_heading_three')}</Text></div>
          {/* <p>(603) 555-0123</p> */}
          <a style={{ textDecoration: "none" }} href="mailto:info@e-simlife.com"><p>info@e-simlife.com </p></a>
          {/* <p>Elgin St. Celina,<br /> Delaware 10299</p> */}
        </div>
        {/* <div className="footer-section newsletter">
          <div className='footer-heading-text'><Text lineheighthigh title>{t('footer_heading_four')}</Text></div>
          <div style={{ display: 'flex', gap: '10px', flexDirection: 'column' }}>
            <TextField label={t('Your email')} onChange={(e) => setEmail(e.target.value)} value={email}
              error={!validateEmail(email) && touched}
            />
            <div>
              <Button Medium type={4} title='Submit' />
            </div>
          </div>
        </div> */}
      </div>
      <div className="footer-bottom">
        <Text lineheighthigh bold DarkGrayTextColor>© {getCurrentYear() + " " + AppConstant.FooterCopyRigthtText}</Text>
      </div>
    </footer>
  );
};

export default Footer;
